<template>
<div class="block sm:hidden text-left cursor-pointer relative p-0 m-0 sm:mx-3 mt-5 w-full mb-2">

  <template v-if="mode!='account'">
      <span @click="openMenu = !openMenu"
        class="block sm:inline-block font-bold cursor-pointer mb-1">{{title}} {{openMenu?'▼':'▶ ...'}}
      </span>
      <template v-if="openMenu">
        <!-- dropdown portion -->
        <template v-if="menuitems && menuitems.length>0">
          <div v-for="(l,i) in menuitems" :key="'menu3'+l.href+i">

            <!-- book type item  -->
            <template v-if="l.ltype==='book'">
              <nuxt-link :to="l.href">
                <!-- book display format  -->
                <div class="relative mb-1 h-12 w-full flex flex-wrap cursor-pointer hover:bg-gray-200" style="min-height: 52px"
                    :class="[l.style==='upcoming'?'opacity-50':'']" >
                  <img class="absolute left-0 top=0 h-12 ml-1 mt-1" :src="l.img || '/book.svg'" />
                  <div class="title ml-10 mr-1 mt-1 pl-2 text-gray-800 text-md">{{l.a}}</div>
                </div>
              </nuxt-link>
            </template>

            <!-- subtitle  -->
            <div v-else-if="l.ltype==='subtitle'" class="text-center text-italic text-xs text-gray-400 -mt-2">{{l.a}}</div>

            <!-- divider have no l.a  -->
            <hr v-else-if="l.ltype==='hr'" class="w-full"  />

            <template v-else-if="l.href && l.a">
              <!-- internal link  -->
              <nuxt-link v-if="l.href.indexOf('http')<0" :to="l.href" class="block hover:bg-white mb-1 pl-3 hover:shadow hover:rounded">
                <img v-if="l.img" :src="l.img" class="h-6 inline rounded shadow" /> {{l.a}}</nuxt-link>

              <!-- external link  -->
              <a v-else :href="l.href" target="_blank" class="block hover:bg-gray-200 p-2 ">
                <img v-if="l.img" :src="l.img" class="h-6 inline rounded shadow" /> {{l.a}}</a>
            </template>

          </div>
        </template>
      </template>
  </template>

  <template v-else>
    <template v-if="user && account && user.email">
      <span class="block sm:inline-block font-bold"> {{ userDisplayName(account) }} </span>
      <!-- dropdown portion -->
      <div class="" v-for="(l,i) in menuitems" :key="'acc2_'+l.href+i">
        <nuxt-link v-if="l.a.length>0  && l.href.indexOf('http')==-1" :to="l.href"
          class="block hover:bg-white mb-1 pl-3 hover:shadow hover:rounded" :class="l.class">
          <img v-if="l.img" :src="l.img" class="h-6 inline rounded shadow" /> {{l.a}}
        </nuxt-link>
        <a v-else-if="l.a.length>0" :href="l.href" target="_blank" class="block hover:bg-gray-200 p-2 ">{{l.a}}</a>
        <hr v-else class="w-1/2 mt-1 mb-1 shadow" />
      </div>
    </template>
    <!-- logged-out state  -->
    <nuxt-link v-else to="/account/login"
      class="block font-bold text-center mt-5 p-2 bg-white rounded-lg shadow-md hover:shadow-lg hover:text-teal-600 max-w-xs center">Login
    </nuxt-link>
  </template>

</div>
</template>



<script>
import usertools from '~/mixins/usertools'

export default {
  mixins: [usertools],
  props: ['title', 'menuitems', 'mode'],
  data() {
    return {
      openMenu: true,
    }
  },
}
</script>