import { mapGetters, mapState, mapMutations } from 'vuex'
import Hashids from 'hashids/cjs'
import lzstring from 'lz-string'
import { storage } from 'firebase'

export default {
  data () {
    return {
      verified_translations: {},
      unverified_translations: {},
      allDecks: {},
      defaultHeight: 500,
      blocksHeight: 500,
      account_check_counter: 0,
      blocksDbLoaded: false,
      cards: {}, decks: {},
      freeBooks: ['arabic_primer_en',],
    }
  },

  computed: {
    ...mapState('books', ['books', 'blocks', 'selectionToken', 'userReadingLevel', 'currentBook', 'blocksReady', 'booksReady', 'booksLoading', 'blocksLoading']),
    ...mapState('courses', ['allCourses']),
    ...mapGetters('books', [ 'getCurrentBook', 'getBlock', 'getLanguageList', 'getSelectionObj', 'getBook']),
    isAdmin() { return this.isAdministrator() },
    book() { return this.getCurrentBook },
    currentBookLoaded() { return (this.book.id===this.bookid && this.blocksReady) },

    blids() {
      if (!this.booksReady || !this.book) return []
       else return this.book.blocks.filter((blid, index)=> (index===this.book.blocks.indexOf(blid)) )
    },

    currentLang(){
      return this.book.lang2
    },

    chapterHeaders() {
      if (!this.book || !this.blocksReady) return []
      let book = this.book
      let list = this.blids.filter(blid => {
        let block = this.getBlock(blid)
        return block.type==='header' && !block.deleted
      }).map(blid => this.blockTOC_Obj(blid))
      return list
    },
    block_array() {
      if (!this.book || !this.blids) return []
      return this.blids.map(blid => this.getBlock(blid))
    },

    // for use in view-only mode
    block_view_blocks() {
      if (!this.book || !this.blids) return []
      return this.block_array.filter(block => !(block.deleted))
    },
    block_view_blids() {
      if (!this.book || !this.blids) return []
      return this.block_view_blocks.map(block => block.id)
    },

    headersInfo() {
      if (!this.blocksReady) return []
      return this.headerList.map(blid => this.blockTOC_info(blid))
    },
    headerList() {
      if (!this.blocksReady) return []
      let book = this.getCurrentBook
      if (!book || !book.blocks) return console.log('Warning, currentBook did not work right')
      return book.blocks.filter(blid => this.getBlock(blid).type==='header')
    },
    allItems() {  // list of available books and decks
      if (!this.booksReady) return {}
      // object containing all books and all decks
      let obj = {}
      // console.log('Compiling list of items, books:', this.books.length, ', decks:', this.allDecks.length)
      this.books.forEach(book => obj[book.id]=book)
      for (let id in this.allDecks) { obj[id]=this.allDecks[id] }
      // console.log('allItems:', obj)
      return obj
    },
    allLanguages() {
      let result = {}
      this.getLanguageList.forEach(code => result[code] = this.languageName(code))
      // console.log('all languages:', result)
      return result
    },
    allCourseIDs() {
      return this.allCourses.map(cr => cr.id)
    },
    allBookIDs() {
      if (!this.booksReady) return []
      return this.books.map(cr => cr.id)
    },
    coursesLookup() {
      let result = {}
      this.allCourses.forEach(crs => result[crs.id]=crs)
      return result
    },
    booksLookup() {
      let result = {}
      if (!this.booksReady) return result
      this.books.forEach(book => result[book.id]=book)
      return result
    }
  },



  methods: {
    ...mapMutations('books', ['setSelectionToken', 'setBlocksLoading', 'setBlocksReady',
                    'setCurrentBook', 'setBlock', 'setBlocks', 'removeBlock', 'setClearBlocks' ]),

    async pause(ms=1000) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },

    nextBlock(blid) {
      // if (!blid) blid = this.curr
      let nblid = this.book.blocks[this.book.blocks.indexOf(blid)+1]
      if (nblid) return this.getBlock(nblid); else return false
    },

    editBookContent(bookid) {
      // if (!bookid) bookid = this.sel
      if (!bookid) return
      let book = this.getBook(bookid)
      if (book) {
      //  this.$toasted.show(`<h4>Loading: “${book.title}”</h4>`)
       this.$store.dispatch('books/loadBook', book.id)
      } else console.err('!Warning. Book not found:', bookid)
    },
    blockWords(blid) { // array of words from a block, used to generate the TOC menu
      let result= []
      let block = this.getBlock(blid)
      for (let sent of block.sentences) for (let phr of sent.phrases) for (let w of phr.words) result.push(w)
      return result
    },


    blockTOC_Obj(blid) {
      let block = this.getBlock(blid)
      let words = this.blockWords(blid)
      let l2 = words.map(w => w.l2.trim()).join(' ')
      let l1 = block.sentences[0].phrases[0].phrase.l1
      if (!l1 || l1==='+') l1 = `(${ words.map(w=>w.l1.trim()).join(' ') })`
      let proofed = true
      words.map(w => { if (!w.proofed) proofed=false })
      let published = block.published
      return {l1, l2, blid, proofed, published}
    },

    setSelectToken(token) {  // clicking a word sets the selection token
      this.setSelectionToken(token)
      // console.log('setSelectToken', token)
      let text = ''
      let {blid, type, snt, phr, wrd} = this.tokenObj(token)
      this.editItemToken = token
      let block = this.getBlock(blid)
      if (type==='phr') {
        let phrase = this.getPhrase(token)
        this.editItemText = phrase.phrase.l1 || ''
      } else if (type==='wrd') {
        let word = this.getWord(token)
        this.editItemText = word.l1 || ''
      }
      // console.log('setSelectToken', token, type, this.editItemText)
    },

    isContentBlock(type) {
      return (['par', 'header', 'title'].indexOf(type) > -1)
    },

    isSelected(blid, type, snt, phr=0, wrd=0) {
      if (!type) return false
      let sel = Object.freeze(this.getSelectionObj)
      if (!sel || !sel.blid || !sel.type) return false
      if (blid!==sel.blid  || type!==sel.type) return false
      if (type==='snt') return (snt===sel.snt)
      if (type==='phr') return (snt===sel.snt) &&  (phr===sel.phr)
      if (type==='wrd') return (snt===sel.snt) && (phr===sel.phr) && (wrd===sel.wrd)
    },
    blockType(blid) {
      let block = this.getBlock(blid)
      if (block) return block.type
    },
    blockTOC_info(blid) {
      let block = this.getBlock(blid)
      let words = this.blockWords(blid)
      let published = block.published
      let l2 = words.map(w => w.l2.trim()).join(' ')
      let l1 = block.sentences[0].phrases[0].phrase.l1
      if (!l1 || l1==='+') l1 = `(${ words.map(w=>w.l1.trim()).join(' ') })`
      let proofed = true
      words.map(w => { if (!w.proofed) proofed=false })
      return {l1, l2, blid, proofed, published}
    },
    blockWords(blid) {
      let result = []
      let block = this.getBlock(blid)
      if (!block || !block.sentences || !block.sentences[0].phrases) return []
      for (let [snt, sent] of block.sentences.entries()) {
        for (let [phr, phrase] of sent.phrases.entries()) {
          for (let [wrd, w] of phrase.words.entries()) {
            // w.snt = snt
            // w.phr = phr
            w.tkn = this.tokenize(blid, 'wrd', snt, phr, wrd)
            result.push(w)
          }
        }
      }
      return result
    },
    phraseWords(blid, snt, phr, phrase) {
      let result = []
      for (let [wrd, w] of phrase.words.entries()) {
        // w.snt = snt
        // w.phr = phr
        w.tkn = this.tokenize(blid, 'wrd', snt, phr, wrd)
        result.push(w)
      }
      return result
    },
    blockPhrases(blid) {
      let result = []
      let block = this.getBlock(blid)
      if (!block || !block.sentences || !block.sentences[0].phrases) return []
      if (block.sentences) for (let [snt, sent] of block.sentences.entries()) {
        // if (sent.phrases) console.log(`Sentence [${snt}], has phrases: ${sent.phrases.length}`)
        if (sent.phrases) for (let [phr, phrase] of sent.phrases.entries()) {
          // if (phrase && phrase.words && !phrase.phrase) {
          //   //console.error(`Cannot locate phrase.phrase`, blid+':'+snt+':'+phr, phrase, )
          //   phrase.phrase = {l1:'',l2:'',audio_l1:'',tts:''}
          // } else
          if (phrase && phrase.words) {
            if (!phrase.phrase) phrase.phrase = {l1:'', l2:'',audio_l1:'',tts:''}
            result.push({
              words: this.phraseWords(blid, snt, phr, phrase),
              tkn: this.tokenize(blid, 'phr', snt, phr),
              l1: phrase.phrase.l1, audio_l1: phrase.phrase.audio_l1, lang1: block.lang1, tts: phrase.phrase.tts,
            })
        }
        }
      }
      return result
    },

    initilizeDeckData(){
      this.$fireModule.database().ref(`decks`).on("value", (snapshot) => {
        if (snapshot) snapshot.forEach(deckSnap => {
          // this.allDecks[deckSnap.key] = deckSnap.val()
          this.$set(this.allDecks, deckSnap.key, deckSnap.val())
          // console.log(this.allDecks)
        })
      })
    },

    coursesbylang(lang) {
      return this.filteredCourses.filter(c=>(lang===c.lang2))
    },
    bookDeckType(id) {
      if (this.allItems[id]) return this.allItems[id].type
    },

    fltr_desc(desc='', course) {
      // [cards] [decks] [books] [words] [blocks] [reading_hours] [completion_hours] [desc] [shortdesc]
      if (!desc || !desc.trim()) desc = '[desc]'
      if (!course) course = this.course
      // let r = course.description
      let s = course.content_summary
      s.words = s.words || 0
      s.reading_time = s.reading_time || 0
      s.books = s.books || 0
      s.completion_time = s.completion_time || 0
      s.cards = s.cards || 0

      let reading_hours = (s.reading_time / 1000 / 60 / 60).toFixed(1).toLocaleString()
      let completion_hours = (s.completion_time / 1000 / 60 / 60).toFixed(1)
      // let lang = this.languageName(course.lang2)
      return desc.replace(/\[books\]/g, s.books).replace(/\[reading_hours\]/g, reading_hours)
           .replace(/\[cards\]/g, s.cards.toLocaleString())
           .replace(/\[completion_hours\]/g, completion_hours)
           .replace(/\[decks\]/g, s.decks).replace(/\[lang\]/g, this.languageName(course.lang2))
           .replace(/\[words\]/g, s.words.toLocaleString())
           .replace(/\[blocks\]/g, s.blocks)
           .replace(/\[desc\]/g, s.desc).replace(/\[shortdesc\]/g, s.shortdesc)
           .replace(/\[est_semesters\]/g, Math.round(s.est_semesters))
           .replace(/\[lang1\]/g, this.languageName(course.lang1)).replace(/\[lang2\]/g, this.languageName(course.lang2))
           .replace(/\[est_pages\]/g, Math.round(s.words / 250))
           //est_semesters
    },
    languageName(langCode) {
      let names = { en: 'English', fa: 'Farsi', es: 'Spanish', ar: 'Arabic', ru: 'Russian' }
      return (names.hasOwnProperty(langCode)) ? names[langCode] : langCode
    },
    parseContent(content) {
      const parser = require('ocnparse')
      return parser.tokenize(content)
    },
    asset_url(url) {
      if (!url) return
      if (url.indexOf('http') > -1) return url
      if (url.indexOf('data:image/jpeg;base64') > -1) return url
      return 'https://llab19.s3.amazonaws.com/' + url
    },

    isValidBLID(blid) {
      return !!(this.book.blocks.indexOf(blid) >= 0)
    },


    // database editing tools

    deleteWord(token) { // update word object
      console.log('deleteWord', token)
      if (!token) return
      let {blid, snt, phr, wrd} = this.tokenObj(token)
      let block = this.getBlock(blid)

      // console.log('========================')
      // console.log(`deleteWord(${token})`, JSON.stringify(block.sentences.slice(), null, 2))

      block.sentences[snt].phrases[phr].words = block.sentences[snt].phrases[phr].words.splice(wrd, 1)
      console.log('Modified words: ', block.sentences[snt].phrases[phr].words )
      if (block.sentences[snt].phrases[phr].words.length===0) {
        console.log('deleting phrase')
        block.sentences[snt].phrases = block.sentences[snt].phrases.splice(phr, 1)
        if (block.sentences[snt].phrases.length===0) {
          console.log('deleting sentence')
          block.sentences = block.sentences.splice(snt, 1)
        }
      }
    },

    saveBlockDB(block) {
      if (!block) return
      // let block = this.getBlock(blid)
      let path = `${block.book}/${block.id}/`
      if (!block.modified) block.modified = `${new Date()}`
      // now push changes to db
      this.$fireModule.database().ref('blocks').child(path).update(block).then(() => {
        console.log('Updated block', block.id)
      })
      .catch(error => console.error(error))
    },

    updateBlockMeta(blid, update) {
      // update = {published: true}
      if (!update) return
      let block = this.getBlock(blid)
      let path = `${block.book}/${blid}/`
      if (!update.modified) update.modified = `${new Date()}`
      // now push changes to db
      this.$fireModule.database().ref('blocks').child(path).update(update).then(() => {
        // this.$toasted.show(`<p>Updated block: “${blid}”</p>`)
        console.log('Modified block', blid, update)
      })
      .catch(error => console.error(error))
    },
    updatePhraseMeta(tkn, update) {
      let {blid, snt, phr} = this.tokenObj(tkn)
      if (!update || !blid) return
      let block = this.getBlock(blid)
      let path = `${block.book}/${blid}/sentences/${snt}/phrases/${phr}/phrase/`
      // now push changes to db
      this.$fireModule.database().ref('blocks').child(path).update(update).then(() => {
        // this.$toasted.show(`<p>Updated block: “${blid}”</p>`)
      })
      .catch(error => console.error(error))
      this.updateBlockMeta(blid, {modified: `${new Date()}`, published: false})
    },

    updateWord(token, update, block=null) { // update word object
      console.log('updateWord', token, update)
      if (!update || !token) return
      let {blid, snt, phr, wrd} = this.tokenObj(token)
      if (!block) block = this.getBlock(blid)
      // mechanism to flag a word as deleted
      update.deleted = !!(update.l2==="(delete)")
      let path = `${block.book}/${blid}/sentences/${snt}/phrases/${phr}/words/${wrd}`
      // now push changes to db
      this.$fireModule.database().ref('blocks').child(path).update(update).then(() => {
        //  console.log(`Updated Word: “${token}”`, update)
      })
      .catch(error => console.error(error))
      this.updateBlockMeta(blid, {modified: `${new Date()}`})
    },
    updatePhrase(token, update, block=null) { // update word object
      console.log('updatePhrase', token, update)
      if (!update || !token) return
      let {blid, snt, phr} = this.tokenObj(token)
      if (!block) block = this.getBlock(blid)
      let path = `${block.book}/${blid}/sentences/${snt}/phrases/${phr}/phrase`
      this.$fireModule.database().ref('blocks').child(path).update(update).then(() => {
       // this.$toasted.show(`<p><br>Updated Phrase: <br><br>“${path}”</p>`)
      })
      .catch(error => console.error(error))
      this.updateBlockMeta(blid, {modified: `${new Date()}`})
    },
    updateSentence(token, update, block=null) { // update sentence object
      //console.log('updateSentence', token, update)
      if (!update || !token) return
      let {blid, snt, phr, wrd} = this.tokenObj(token)
      if (!block) block = this.getBlock(blid)
      let path = `${block.book}/${blid}/sentences/${snt}/sentence`
      this.$fireModule.database().ref('blocks').child(path).update(update).then(() => {
       // this.$toasted.show(`<p><br>Updated Sentence: <br><br>“${path}”</p>`)
      })
      .catch(error => console.error(error))
      this.updateBlockMeta(blid, {modified: `${new Date()}`})
    },
    splitPhrase(token){
      //console.log('splitting the phrase', token)
      if (!token) return
      // validate: must be inside a phrase
      let {blid, snt, phr, wrd} = this.tokenObj(token)
      // console.log(`splitting`, {blid, snt, phr, wrd})
      let sentence = Object.assign({}, this.getBlock(blid).sentences[snt])
      // cannot split on the last word of a phrase, just in case we tried
      if (!(wrd<sentence.phrases[phr].words.length-1)) return
      // copy of phrases array
      let phrases = sentence.phrases.slice(0)
      let phrase = phrases[phr]

      // console.log(phrase)

      const updatedSentence = {
        ...sentence,
        phrases: Array.prototype.concat(
          phrases.slice(0, phr), // any phrases before this phrase
          [ // this phrase split in two
            {phrase: {l1: String(phrase.phrase.l1), proofed: false},
              words: phrase.words.slice(0, wrd+1)},
            {phrase: {l1: String(phrase.phrase.l1), proofed: false},
              words: phrase.words.slice(wrd+1)}
          ],
          phrases.slice(phr+1) // any phrases after this phrase
        )
      }

      let block = this.getBlock(blid)
      let path = `${block.book}/${blid}/sentences/${snt}`
      this.$fireModule.database().ref('blocks').child(path).update(updatedSentence).then(() => {
        this.$toasted.show(`<p><br>Updated Sentence: <br><br>“${path}”</p>`)
      })
      this.updateBlockMeta(blid, {modified: `${new Date()}`})
    },
    mergePhrase(token){
      if (!token) return
      // validate: must be inside a phrase
      let {blid, snt, phr, wrd} = this.tokenObj(token)
      let sentence = this.getBlock(blid).sentences[snt]
      // can only join on the last word of a phrase
      if (wrd !== sentence.phrases[phr].words.length-1) return
      // cannot join phrase with next if no more phrases
      if (phr >= sentence.phrases.length-1) return

      // copy of phrases array
      let phrases = sentence.phrases.slice(0)
      let phrase = phrases[phr]
      let nextPhrase = phrases[phr + 1]
      let newPhrase = phrase.phrase.l1 === nextPhrase.phrase.l1 ? phrase.phrase.l1 : phrase.phrase.l1 +' '+nextPhrase.phrase.l1
      sentence.phrases = Array.prototype.concat(
        phrases.slice(0, phr), // any phrases before this phrase
        [ // this phrase split in two
          { phrase: {l1: newPhrase, proofed: false},
            words: Array.prototype.concat(phrase.words, nextPhrase.words)
          },
        ],
        phrases.slice(phr+2) // any phrases after this phrase
      )
      let block = this.getBlock(blid)
      let path = `${block.book}/${blid}/sentences/${snt}`
      this.$fireModule.database().ref('blocks').child(path).update(sentence).then(() => {
        // this.$toasted.show(`<p><br>Updated Sentence: <br><br>“${path}”</p>`)
      })
      this.updateBlockMeta(blid, {modified: `${new Date()}`})

    },

    // TODO: these are just copies of splitphrase now...
    splitSentence(token){
      //console.log(`splitSentence: ${token}`)
      // if (!token || token.split(':').length!=6) return
      let {blid, snt, phr} = this.tokenObj(token)
      let block = this.getBlock(blid)
      if (!block || phr>=block.sentences[snt].phrases.length-1) return
      // console.log(`splitting sentence ${snt} at phrase ${phr}, phrase count: ${block.sentences[snt].phrases.length}`, block)
      let sent1 = Object.assign({}, block.sentences[snt])
      let sent2 = Object.assign({}, block.sentences[snt])
      // first half
      sent1.phrases = sent1.phrases.slice(0,phr+1)
      sent1.sentence.proofed = false
      // second half
      sent2.phrases = sent2.phrases.slice(phr+1)
      sent2.sentence.proofed = false
      // create new sentence array
      let sentences = Array.prototype.concat(
        block.sentences.slice(0, snt), // any sentences before the one we are splitting
        [sent1, sent2],                // the sentence we just split
        block.sentences.slice(snt+1)   // any sentences after the one we are splitting
      )
      // save to DB -- replace entire sentences array with an object {}
      //block.sentences = sentences
      // let block = this.getBlock(blid)
      let path = `${block.book}/${blid}/`
      this.$fireModule.database().ref('blocks').child(path).update({sentences}).then(() => {
       // this.$toasted.show(`<p><br>Updated Sentences: <br><br>“${path}”</p>`)
      })
      this.updateBlockMeta(blid, {modified: `${new Date()}`})
    },
    mergeSentence(blid, snt){
      // console.log(`mergeSentence, blid: ${blid}, snt: ${snt}`)

      let block = this.getBlock(blid)
      // snt must not be last sentence
      if (!block || snt>=block.sentences.length-1) return
      let sent1 = Object.assign({}, block.sentences[snt])
      let sent2 = Object.assign({}, block.sentences[snt+1])
      sent1.phrases = Array.prototype.concat( sent1.phrases, sent2.phrases )
      if (sent1.sentence.l1 != sent2.sentence.l1) sent1.sentence.l1 += ' ' + sent2.sentence.l1
      sent1.sentence.proofed = false
      // remove sent2
      let sentences = Array.prototype.concat(
        block.sentences.slice(0, snt), // any sentences before the two we are merging
        [sent1],                       // the merged sentence
        block.sentences.slice(snt+2)   // any sentences after the two we are merging
      )
      // console.log('mergeSentence result: ', JSON.stringify(sentences, null, 2))
      //block.sentences = sentences
      // push modified sentences back to DB
      // let path = `${blid}/`
      let path = `${block.book}/${blid}/`
      this.$fireModule.database().ref('blocks').child(path).update({sentences}).then(() => {
        //  this.$toasted.show(`<p><br>Updated Sentences: <br><br>“${path}”</p>`)
      })
      this.updateBlockMeta(blid, {modified: `${new Date()}`})
    },
    tokenID (token) {
      let [blid, type, snt, phr, wrd] = token.split(':').map((v, i) => i <= 1 ? v : parseInt(v))
      if (blid.indexOf('bl') == 0) blid = blid.substring(2)
      if (type === null) return 'bl' + blid
      const hashids = new Hashids("llab")
      if (type === 'snt') return `snt${blid}` + hashids.encode(snt)
        else if (type === 'phr') return `phr${blid}` + hashids.encode(snt,phr)
        else if (type === 'wrd') return `w${blid}` + hashids.encode(snt,phr,wrd)
    },
    tokenize(blid, type=null, snt=null, phr=null, wrd=null) {
      if (!type) return blid
        else if (type==='snt') return `${blid}:snt:${snt}`
        else if (type==='phr')  return `${blid}:phr:${snt}:${phr}`
        else if (type==='wrd')  return `${blid}:wrd:${snt}:${phr}:${wrd}`
    },
    tokenObj(token) {
      if (!token) {
        console.error('called tokenObj with empty token')
        return {}
      }
      let [blid, type, snt, phr, wrd] = token.split(':').map((v,i)=> i<=1 ? v : parseInt(v))
      if (!type) return {blid}
        else if (type==='snt') return {blid,type,snt}
        else if (type==='phr') return {blid,type,snt,phr}
        else if (type==='wrd') return {blid,type,snt,phr,wrd}
    },

    setSelection(blid, type, snt, phr=0, wrd=0) {
      let book = this.getCurrentBook
      let blindex = book.blocks.indexOf(blid)
    },

    getSentence(token) {
      let t = this.tokenObj(token)
      let block = this.getBlock(t.blid)
      if (block && block.sentences) return block.sentences[t.snt]
    },
    getPhrase(token) {
      let t = this.tokenObj(token)
      let sent = this.getSentence(token)
      if (sent && sent.phrases) return sent.phrases[t.phr]
    },
    getPhraseStr(token) {
      let phrase = this.getPhrase(token)
      return phrase.words.map(w => w.l2).join(' ')
    },
    getWord(token) {
      let t = this.tokenObj(token)
      let phrase = this.getPhrase(token)
      if (phrase && phrase.words) return phrase.words[t.wrd]
    },



    dir(lang="en") {
      return ['fa','ar','he'].indexOf(lang)>-1 ? 'rtl' : 'ltr'
    },

    scroll2token(token) {
      if (!token) return
      let k = this.tokenObj(token)
      if (!k.blid) return
      if (this.$refs.scroller) this.$refs.scroller.setIndex(this.blids.indexOf(k.blid))
    },

    checkForDuplicateBlocks() {
      let book = this.getCurrentBook
      let blocks = book.blocks
      if (blocks && blocks.length>0) blocks.forEach((blid, ind) => {
        if (blocks.indexOf(blid)!=ind) {
          let block = this.getBlock(blid)
          console.log(`Block ${blid} duplicated at ${ind}, type: ${block.type}`)
        }
      })
    },

    blockNeedsTTS(blid) {
      let block = this.getBlock(blid)
      if (block.trtype==='none' || block.trtype==='words') return []
      // check to see if tts is missing
      let phrases = this.blockPhrases(blid).filter(phrase => {
        let str = (phrase.l1||'')
        if (str.length<2) return false // must have some text
        if (phrase.audio_l1 && str===phrase.tts) return false
        if (!phrase.audio_l1 || str!==phrase.tts) return true
      })
      if (phrases.length) console.log(`Need TTS on ${phrases.length} phrses:`, phrases)
       // else console.log('No phrases needing TTS')
      return phrases
    },

    BlockHasAudio(block) {
      if (block.type==='hr' || block.type==='img' || !block.sentences) return false
      for (const sentence of block.sentences)
        for (const phrase of sentence.phrases)
          for (const word of phrase.words) if (word.aud) return true
      return false
    },

    blockHasProofedWords(bl) {
      let words = this.blockWords(bl)
      for (word of words) if (word.proofed) return true
    },
    blockHasProofedPhrases(bl) {
      let phrases = this.blockPhrases(bl)
      for (phrase of phrases) if (phrase.proofed) return true
    },
    // all text blocks should have a tr_type
    validate_trtype(bl) {
      // must be a text type
      if (['par', 'header', 'title'].indexOf(bl.type)<0) return null
      // if already assigned, return
      if (['none', 'phrases', 'words'].indexOf(bl.trtype)>-1) return bl.trtype
      // if english, no translations needed
      if (bl.lang2 === 'en') return 'none'
      // default for paragraph is 'words'
      if (bl.type==='par') return 'words'
      // default for titles and headers depending on whether we have proofed phrases or words
      if (this.blockHasProofedPhrases(bl)) return 'phrases'
        else if (this.blockHasProofedWords(bl)) return 'words'
          else return 'none'
    },

   // returns normalized block using Firebase block. If no block passed, returns loading object
   block_normalize(blid, sourceBlockObj=null) {
    if (!sourceBlockObj) return {id: blid, type: 'loading'}
    // let bl = Object.create(sourceBlockObj)
    let bl = JSON.parse(JSON.stringify(sourceBlockObj))
    bl.dir1 = this.dir(bl.lang1 || this.book.lang1)
    bl.dir2 = this.dir(bl.lang2 || this.book.lang2)
    bl.isContentType = ['par', 'title', 'header'].indexOf(bl.type)>-1
    bl.hasAudio = this.BlockHasAudio(bl)
    // bl.tts_phrases = this.blockNeedsTTS(bl.id)
    bl.token = bl.id
    bl.trtype = this.validate_trtype(bl) // validate or set defaults
    bl.classes = this.token_classes(bl.id, bl)
    if (bl.sentences) bl.sentences.forEach((sent, snt) => {
      sent.token = this.tokenize(bl.id, 'snt', snt)
      sent.id = this.tokenID(sent.token)
      sent.classes = this.token_classes(sent.token, bl)
      if (sent.phrases) sent.phrases.forEach((phrase, phr)=>{
        phrase.token = this.tokenize(bl.id, 'phr', snt, phr)
        phrase.id = this.tokenID(phrase.token)
        phrase.classes = this.token_classes(phrase.token, bl)
        phrase.classesl1 = `phrasel1 ${phrase.phrase && phrase.phrase.proofed?'verified':''}`.split(' ').filter(c => c!='edsel').join(' ')
        if (!phrase.phrase) phrase.phrase = {l1:'', l2:'',audio_l1:'',tts:''}
        if (phrase.words) phrase.words.forEach((w,wrd) => {
          w.token = this.tokenize(bl.id, 'wrd', snt, phr, wrd)
          w.id = this.tokenID(w.token)
          w.classes = this.token_classes(w.token, bl)
          w.deleted = (w.l2==='(delete)')
          w.l2 = (String(w.l2)||'').trim().replace(/\s/g, '&nbsp;')
          w.l1 = (String(w.l1)||'').trim()
        })
      })
    })
    if (bl.type==="flashcards") {
      // get cards count and card data
      if (!bl.cards) bl.cards = []
      if (!bl.decks) bl.decks = []
    }
    // console.log(bl.id, bl.type)
    return bl
  },





  getBlocksHeight() {
    let scrollArea = document.getElementById('blocks')
    if (scrollArea) this.blocksHeight = scrollArea.clientHeight + 35 // ????? why do I have to add 35?
  },

  token_classes(token, block=null, editmode=true) {
      const MAX_PHRASE_LENGTH = 8
      let {blid, type, snt, phr, wrd} = this.tokenObj(token)
      type = type || 'blk'
      let result = []
      if (!block) block = this.getBlock(blid)
      // now type specific
      if (type==='blk') {
        result.push('blk')
        result.push(block.type)
        result.push((block.classes || '').split(' '))
        if (block.published) result.push('published')
        // if (block.trtype) result.push(block.trtype==='words'?'trwords':block.trtype==='phrases'?'trphrases':'trnone')
      } else if (type==='snt') {
        result.push('snt')
        let sent = block.sentences[snt]
        result.push((sent.classes || '').split(' '))
        if (sent.sentence.proofed) result.push('verified'); else result = result.filter(cl => cl!='verified')
      } else if (type==='phr') {
        result.push('phrase')
        let phrase = block.sentences[snt].phrases[phr]
        if (!phrase) console.log('Weird phrase: ', token)
        result.push( (phrase.classes || '').split(' ') )
        if (phrase.phrase && phrase.phrase.proofed) result.push('verified'); else result = result.filter(cl => cl!='verified')
        if (phrase.words.length > MAX_PHRASE_LENGTH) result.push('longphr'); else result = result.filter(cl => cl!='longphr')
      } else if (type==='wrd') {
        let w = block.sentences[snt].phrases[phr].words[wrd]
        result.push('w')
        result.push((w.classes || '').split(' '))
        if (w.proofed) result.push('verified'); else result = result.filter(cl => cl!='verified')
      }
      result = this.cleanClassList(result)
      // console.log(`classes for ${token}: "${result}"`)
      return result
    },

    initializeBooksList() {
      if (!this.booksReady && !this.booksLoading) this.$store.dispatch("books/bindBooksRef")
    },




    checkEditPermisions() {
      this.initializeBooksList()
      if (this.booksReady && !this.blocksReady && !this.blocksLoading) this.loadBookBlocks(this.bookid)
      if (!!this.account && this.booksReady) {
        this.checkForDuplicateBlocks()
        console.log('Account verified!')
        if (!this.userHasBookEditAccess(this.account, this.bookid)) return this.$router.push(`/`)
      } else {
        this.account_check_counter++
        console.log('Waiting for account permissions...')
        setTimeout(this.checkEditPermisions, 50 * this.account_check_counter * this.account_check_counter)
      }
    },
    checkReadPermisions() {
      console.log('checkReadPermisions', this.booksReady, this.booksLoading, this.blocksReady, this.account)
      if (!this.booksReady && !this.booksLoading) this.$store.dispatch("books/bindBooksRef")
      if (this.booksReady && !this.blocksReady && !this.blocksLoading) this.loadBookBlocks(this.bookid)
      if (!!this.account && this.booksReady) {
        this.checkForDuplicateBlocks()
        console.log('Account verified!')
        if (!this.userHasBookReadAccess(this.account, this.bookid)) return this.$router.push(`/`)
      } else {
        this.account_check_counter++
        console.log('Waiting for account permissions...', this.account_check_counter)
        if (this.account_check_counter>10) {
          // console.log(this.$route.params.bid)
          let bookid = this.$route.params.bid
          if (bookid) return this.$router.push(`/account/login?redirect=/book/${bookid}`)
        }
        setTimeout(this.checkReadPermisions, 50 * this.account_check_counter * this.account_check_counter)
      }
    },


    loadCards(cards=[]) {
      // console.log('loadCards', cards)
      let localReadCard = (fpid) => {
        const CACHE_TIMEOUT = 7 * 24 * 60 * 60 * 1000; // one week in ms
        // const CACHE_TIMEOUT = 60 * 1000; // one minute in ms
        if (this.cards[fpid]) return this.cards[fpid]
        let card = JSON.parse(process.browser ? window.localStorage.getItem(`llab-${fpid}`) : '')
        if (!card || !card.card || !card.savedDate || (card.savedDate < (new Date()).getTime()-CACHE_TIMEOUT)) return false
        this.$set(this.cards, fpid, card.card)
        // console.log('load card read', fpid, card.card, this.cards)
        return card.card
      }
      let localWriteCard = (fpid, card) => {
        if (process.browser) window.localStorage.setItem(`llab-${fpid}`, JSON.stringify({savedDate:Date.now(), card }))
        this.$set(this.cards, fpid, card) // to cause reactive updates
        console.log('local card written', fpid, card)
      }

     let staleCards = cards.filter(fpid => !localReadCard(fpid))
      // let staleCards = cards.filter(()=>true)
      // console.log('Loading or refreshing', staleCards.length, 'cards', staleCards)
      // mark cards as loading so we won't try to fetch them twice
      staleCards.forEach(fpid => this.$set(this.cards, fpid, {id: fpid, type: 'loading' }) )
      // load cards from db and save
      staleCards.forEach(fpid => {
        // console.log('DB fetched card:', fpid)
        this.$fireModule.database().ref(`flashcards/${fpid}`).on("value", (snapshot) => {
          let card = snapshot.val()
          console.log('DB fetched card:', card)
          if (snapshot) localWriteCard(fpid, card)
        })
      })
    },

    loadDecks(decks=[]) {
      let localReadDeck = (deckid) => {
        const CACHE_TIMEOUT = 7 * 24 * 60 * 60 * 1000; // one week in ms
        // const CACHE_TIMEOUT = 60 * 1000; // one minute in ms
        if (this.decks[deckid]) return this.decks[deckid]
        let deck = JSON.parse(process.browser ? window.localStorage.getItem(`llab-${deckid}`) : '')
        if (!deck || !deck.savedDate || (deck.savedDate<(new Date()).getTime()-CACHE_TIMEOUT)) return false
        this.$set(this.decks, deckid, deck)
        return deck
      }
      let localWriteDeck = (deckid, deck) => {
        if (process.browser) window.localStorage.setItem(`llab-${deckid}`, JSON.stringify({savedDate:Date.now(), deck }))
        this.$set(this.decks, deckid, deck) // to cause reactive updates
      }
      let staleDecks = decks.filter(deckid => !localReadDeck(deckid))
      // mark each deck as loading so we won't try to reload
      staleDecks.forEach(deckid => this.$set(this.decks, deckid, {id: deckid, type: 'loading'}))
      // load each deck and try to load cards
      staleDecks.forEach(deckid => {
        this.$fireModule.database().ref(`decks/${deckid}`).on("value", (snapshot) => {
          if (snapshot) {
            let deck = snapshot.val()
            this.$set(this.decks, deckid, deck)
            this.loadCards(deck.cards)
          }
        })
      })
    },

    loadBlockCards(blocks=[]) {
      blocks.forEach(block => {
        if (block.type==='flashcards') {
        if (block.decks) this.loadDecks(block.decks)
        if (block.cards) this.loadCards(block.cards)
        }
      })
    },

    stripDiacritcs(str) {
      return str.replace(/([^\u0621-\u063A\u0641-\u064A\u0660-\u0669a-zA-Z 0-9])/g, '')
    },

    loadBookBlocks(bookid, doneCallback=null) {
      // console.log('loadBookBlocks', bookid)
      this.setClearBlocks() // cancel any previous listeners
      this.setBlocksLoading(true)
      this.setCurrentBook(bookid)
      let book = this.getCurrentBook
      // create placeholder objects
      book.blocks.forEach(id => this.setBlock({id, type:'loading'}))
      // load from local storage if exists
      let cachedBlocks = this.getBlocksCache(bookid)
      // start fetching cards in case they were cached previoiusly
      this.loadBlockCards(cachedBlocks)
      // set up cards and blocks for loading
      cachedBlocks.forEach(bl => this.setBlock(bl))
      this.setBlocksReady(true)
      setTimeout(this.initScrollStart, 200)
      let blocksRef = this.$fireModule.database().ref(`blocks/${bookid}/`)
      // initial load
      blocksRef.once('value', (snapshot) => {
        let blocks = []
        snapshot.forEach( (childSnapshot) => {
          let block = childSnapshot.val()
          let normalized = this.block_normalize(block.id, block)
          this.setBlock(normalized)
          blocks.push(normalized)
        })
        // console.log('Completed loading blocks from firebase...')
        // load any unloaded cards
        this.loadBlockCards(blocks)
        // window.localStorage.setItem(`llab_blocks:${bookid}`, JSON.stringify(cached))
        // this.setBlocksReady(true)
        this.setBlocksCache(book)
        this.setBlocksLoading(false)
        // if (doneCallback) doneCallback()
        this.blocksDbLoaded = true

        // setTimeout(()=>{
        //   let stillLoading = this.book.blocks.filter(blid => {
        //     let block = this.getBlock(blid)
        //     return (block.type==='loading')
        //   })
        //   if (stillLoading.length>0) console.log('Some blocks still marked as loading', stillLoading)
        // }, 100)
      })
      // added
      blocksRef.on('child_added', (data) => {
        if (this.blocksReady) {
          // console.log( 'child_added', data.key )
          let block = data.val()
          let normalized = this.block_normalize(block.id, block)
          this.setBlock(normalized)
        }
      })
      // changed
      blocksRef.on('child_changed', (data) => {
        if (this.blocksReady) {
          let newBlock = data.val()
          let oldBlock = this.getBlock(newBlock.id)
          if (oldBlock.modified != newBlock.modified) {
            this.setBlock(this.block_normalize(newBlock.id, newBlock))
          }  else console.log('Changes reported but "modified" unchanged in block', newBlock.id)
        }
      })
      // deleted
      // blocksRef.on('child_removed', (data) => {
      //   if (this.blocksReady) {
      //     console.log( 'child_removed', data.key )
      //     this.removeBlock(data.key)
      //   }
      // })
    },

    getBlocksCache(bookid) {
      try {
        let compressed = process.browser ? window.localStorage.getItem(`llab_blocks:${bookid}`) : ''
        if (compressed) return JSON.parse(lzstring.decompressFromUTF16(compressed)); else return []
      } catch {return []}
    },

    setBlocksCache() {
      let book = this.getCurrentBook
      let start = this.scrollStart
      const SAVE_BLOCKS = 10
      let blocks = []
      for (let i=start; i<book.blocks.length && i<start+SAVE_BLOCKS; i++) blocks.push(this.getBlock(book.blocks[i]))
      if (!blocks.length) return
      try {
        let compressed = lzstring.compressToUTF16(JSON.stringify(blocks))
        console.log('Saving to cached blocks:', start, blocks.length)
        if (process.browser) window.localStorage.setItem(`llab_blocks:${book.id}`, compressed)
      } catch(err) {
        console.log('error saving blocks to store...', err)
      }
    },

    cleanClassList(alist) {
      if (Array.isArray(alist)) alist = alist.join(' ')
      alist = alist.replace('[object object]', '').replace('[object,object]', '').replace(/\,/g, ' ')
      return alist.toLowerCase().split(' ')
              .filter(cl=>!!cl) // remove empties
              .filter((v,i,s) => s.indexOf(v)===i) // remove duplicates
              .filter(cl => cl!=='edsel' && cl!=='selected') // these should not be written into the object
              .join(' ')
    },


    langDir(lang="en") {
      return ['fa','ar','he'].indexOf(lang)>-1 ? 'rtl' : 'ltr'
    },



  },


  mounted () {
    this.initilizeDeckData()
    this.initializeBooksList()
  }
}

