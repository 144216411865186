import { firebaseAction } from 'vuexfire'
// import { database } from "@nuxtjs/firebase"
import Vue from 'vue'

//****************************************/
export const state = () => ({
  currentBook: {id: ''},
  blocks: {},
  books: [],
  selectionToken: '',
  userReadingLevel: 1,

  blocksReady: false,
  blocksLoading: false,

  booksReady: false,
  booksLoading: false,
})


//****************************************/
export const getters = {
  getCurrentBook(state) {
    if (!state.currentBook || !state.currentBook.id) return {id: ''}
    let bid = state.currentBook.id
    if (!bid) return console.error(`Book Store / getCurrentBook: no currentBook.id in state`)
    let book = (state.books || []).filter(bk => bk.id===bid)[0] || false
    if (book) return book
      else console.error(`Book Store / getCurrentBook: no book found with id ${bid}`)
    return false
  },
  getRandomBook(state) {
    return state.books[Math.floor(Math.random()*state.books.length)]
  },
  getBook: (state) => (bookid) => {
    let index = state.books.map(bk=> bk.id).indexOf(bookid)
    if (index>-1) return state.books[index]
  },
  getBlock: (state) => (blid) => {
    if (!state.blocksReady || !state.booksReady || !state.blocks[blid]) return {id: blid, type:'loading'}
    if (state.blocks.hasOwnProperty(blid)) return state.blocks[blid]
      else return false
  },
  getLanguageList(state) { // list of all lang2 language codes
    return state.books.map(bk => bk.lang2).filter((v,i,s) => s.indexOf(v)===i)
  },
  getSelectionObj(state) {
    if (state.selectionToken) return tokenObj(state.selectionToken)
  },
  getSelectionToken(state) {
    return state.selectionToken
  },
}

//****************************************/
export const mutations = {
  setCurrentBook(state, bookid) {
    state.currentBook.id = bookid
  },
  setSelectionToken(state, token) {
    // console.log('set selection token', token)
    state.selectionToken = token
  },
  setUserReadingLevel(state, level) {
    state.userReadingLevel = level
  },
  setClearBlocks(state) {
    state.blocksReady = false
    state.blocksLoading = false
    if (state.currentBook.id) {
      //let ref = database().ref(`blocks/${state.currentBook.id}/`)
      let ref = this.$fireModule.database().ref(`blocks/${state.currentBook.id}/`)
      if (!!ref) ref.off()
    }
    state.currentBook.id=''
    state.blocks = {}
    // Vue.delete(state.blocks)
  },
  setBlock(state, block) {
    // state.blocks[block.id] = block
    Vue.set(state.blocks, block.id, block)
    // console.log('setting block', block)
  },
  setBlocks(state, blocks) {
    blocks.forEach(bl =>  {
      state.blocks[bl.id] = bl
      Vue.set(state.blocks, bl.id, bl)
    })
  },
  removeBlock(state, blid) {
    Vue.delete(state.blocks, blid)
  },

  setBlocksLoading(state, loading) { state.blocksLoading = loading },
  setBlocksReady(state, ready) { state.blocksReady = ready },

}

export const actions = {

  // sync blocks of current book
  loadBook: firebaseAction(async function ({state, getters, commit, bindFirebaseRef}, bookid) {
    console.error('Call to loadBook, no longer used.')
    // if (!bookid || !state.booksReady) return // books not ready yet
    // if (state.blocksLoading && state.currentBook.id===bookid) return // blocks already loaded
    // console.log('Binding to book:', bookid)
    // state.blocksReady = false  // so we won't try to access blocks
    // state.blocksLoading = true  // for wait state
    // state.currentBook.id = bookid
    // state.blocks = {}
    // return bindFirebaseRef('blocks', this.$fireModule.database().ref(`blocks/${bookid}/`), {wait: true}).then(() => {
    //   state.blocksReady = true
    //   state.blocksLoading = false
    // })
  }),

  // metadata of all books
  bindBooksRef: firebaseAction(async function ({state, bindFirebaseRef}) {
    console.log('bindBooksRef')
    state.booksReady = false
    state.booksLoading = true
    bindFirebaseRef('books', this.$fireModule.database().ref('books')).then(() => {
      state.booksReady = true
      state.booksLoading = false
    })
  }),
}



function tokenObj(token) {
  if (!token) return
  let [blid, type, snt, phr, wrd] = token.split(':').map((v,i)=> i<=1 ? v : parseInt(v))
  if (!type) return {blid}
    else if (type==='snt') return {blid,type,snt}
    else if (type==='phr') return {blid,type,snt,phr}
    else if (type==='wrd') return {blid,type,snt,phr,wrd}
}


    // setCurrentBookid(store, bookid) {
    //   // console.log(`setCurrentBookid(${bookid})`, store)
    //   store.bookid = bookid
    // },
    // setCurrentBookLoaded(store, isLoaded) {
    //   // console.log(`setCurrentBookLoaded(${isLoaded})`, store)
    //   store.book_loaded = isLoaded
    // },
    // clearCurrentBookData(store) {
    //   store.book = null
    // },
    // setBlock(store, bid, block) {
    //   if (!store.book) store.book = {}
    //   if (!store.book['l1']) store.book.l1 = {}
    //   Vue.set(store.book.l1, bid, block)
    // },
    // setBlock_l2(store, bid, l2) {
    //   if (!store.book) store.book = {}
    //   if (!store.book['l2']) store.book.l2 = {}
    //   Vue.set(store.book.l2, bid, l2)
    // },
    // setBlock_audiomap(store, bid, mapdata) {
    //   if (!store.book) store.book = {}
    //   if (!store.book['audiomap']) store.book.audiomap = {}
    //   Vue.set(store.book.audiomap, bid, mapdata)
    // },
    // setBlock_words(store, bid, words) {
    //   if (!store.book) store.book = {}
    //   if (!store.book['words']) store.book.words = {}
    //   Vue.set(store.book.words, bid, words)
    // },
    // setBlock_phrases(store, bid, phrases) {
    //   if (!store.book) store.book = {}
    //   if (!store.book['phrases']) store.book.phrases = {}
    //   Vue.set(store.book.phrases, bid, phrases)
    // },
    // setBlock_assignments(store, bid, assignments) {
    //   if (!store.book) store.book = {}
    //   if (!store.book['assignments']) store.book.assignments = {}
    //   Vue.set(store.book.assignments, bid, assignments)
    // },
    // setBlock_meta(store, field, value) {
    //   if (!store.book) store.book = {}
    //   Vue.set(store.book, field, value)
    // },




  // actions: {
  //  loadBook({state, getters, commit, dispatch}, bookid) {
  //   //  console.log(`store: loadBook(${bookid})`)
  //    // if bookid = current_book, exit
  //    if (bookid === state.bookid) return
  //    console.log(`store: loading book: (${bookid})`)
  //    // set book_loaded false  // set book null  // set current_book = bookid
  //    commit('setCurrentBookid', bookid)
  //    commit('setCurrentBookLoaded', false)
  //    commit('clearCurrentBookData')
  //    // load mock data
  //    loadMockData(state, commit).then(() => {
  //      commit('setCurrentBookLoaded', true)
  //      console.log('!!data loaded!!', state.book_loaded)
  //    })
  //  },
  // }





/*********************** helpers */
function loadMockData(state, commit) { // loads mock data into current book object
  // let mock_book_dir = '/testdata/book-hajibaba_fa';
  // let promises = []
  // // l1
  // promises.push(axios.get(`${mock_book_dir}/l1.json`).then(response => {
  //   Object.keys(response.data).forEach(bid => {
  //     response.data[bid].content = response.data[bid].content.split(' | ')
  //     commit('setBlock', bid, response.data[bid])
  //   })
  // }).catch((err)=>console.error(err)))

  // // l2
  // promises.push(axios.get(`${mock_book_dir}/l2.json`).then(response => {
  //   Object.keys(response.data).forEach(bid =>  commit('setBlock_l2', bid, response.data[bid].split(' | ')) )
  // }).catch((err)=>console.error(err)))

  // // audiomap
  // promises.push(axios.get(`${mock_book_dir}/audiomap.json`).then(response => {
  //   Object.keys(response.data).forEach(bid => {
  //     let mapdata = decodeAudioMap(response.data[bid])
  //     commit('setBlock_audiomap', bid, mapdata)
  //   })
  // }).catch((err)=>console.error(err)))

  // // words
  // promises.push(axios.get(`${mock_book_dir}/words.json`).then(response => {
  //   Object.keys(response.data).forEach(bid => {
  //     let words = response.data[bid].split('| ').map(wd => {
  //       return {w: wd.slice(0, -1).trim(), verified: !!parseInt(wd.slice(-1)) }
  //     })
  //     commit('setBlock_words', bid, words)
  //   })
  // }).catch((err)=>console.error(err)))

  // // phrases
  // promises.push(axios.get(`${mock_book_dir}/phrases.json`).then(response => {
  //   Object.keys(response.data).forEach(bid =>  commit('setBlock_phrases', bid, response.data[bid]) )
  // }).catch((err)=>console.error(err)))

  // // assignments
  // promises.push(axios.get(`${mock_book_dir}/assignments.json`).then(response => {
  //   Object.keys(response.data).forEach(bid =>  commit('setBlock_assignments', bid, response.data[bid]) )
  // }).catch((err)=>console.error(err)))

  // // meta
  // promises.push(axios.get(`${mock_book_dir}/meta.json`).then(response => {
  //   let data = response.data
  //   data.blocks = data.blocks.split(',').map(bid => bid.trim())
  //   Object.keys(data).forEach(key =>  commit('setBlock_meta', key, data[key]) )
  // }).catch((err)=>console.error(err)))

  // return axios.all(promises)
}

function decodeAudioMap(enc = '') {  // returns object with audiomap arran and audiosrc
  // let result = {audiomap: [], audiosrc: ''}
  // if (enc.split('|').length != 2) return result
  // result.audiosrc = enc.split('|')[0]
  // let audiomap = enc.split('|')[1].split(',')
  // var start = parseInt(audiomap.shift()) // extra start position at the outset
  // result.audiomap = audiomap.map((item,i)=> {
  //   let len = parseInt(base62.decode(item))
  //   if (!len && i>0) return '' // null words
  //   let result = `${start},${len}`
  //   start += parseInt(len)
  //   return result
  // })
  return result
}




