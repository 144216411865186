<template>
<div class="flex flex-col subpixel-antialiased relative h-screen w-screen overflow-x-hidden overflow-y-hidden">
  <Header />
  <!-- The default template has a scrollable main body area  -->
  <div id="bodycontent" class="fixed p-0 sm:p-2 lg:p-4 overflow-x-hidden overflow-y-auto"
      :style="compactHeight ?
      'top:37px; left:5px; right:5px; bottom:0;':
      'top:37px; left:5px; right:5px; bottom:0px; border-bottom: 5px solid green;'" >
    <nuxt class="flex flex-col h-full w-full relative" />
  </div>
  <Footer v-if="!compactHeight" />
</div>
</template>

<script>
  import Header from '~/components/Header'
  import Footer from '~/components/Footer'
  import usertools from '~/mixins/usertools.js'

  export default {
    components: { Header, Footer },
    data () {
      return {
        clientHeight: 0, // document.documentElement.clientHeight,
      }
    },
    computed: {
      compactHeight() {
        if (process.client) return this.clientHeight < 500
          else return false
      }
    },
    mixins: [ usertools ],
    methods: {
      handleResize() { this.clientHeight = document.documentElement.clientHeight },
      checkForUpdate() {
        // this does not work.
        // if ('serviceWorker' in navigator) {
        //   navigator.serviceWorker.getRegistrations().then((registrations) => {
        //     for (let registration of registrations) registration.update()
        //   })
        // }
      },
    },
    watch: {
      '$route'() { this.checkUserVerified() },
      user() { this.checkUserVerified() },
    },

    mounted: function() {
      if (process.client) {
        window.addEventListener('resize', this.handleResize)
        setTimeout(this.handleResize, 50)
      }
      // this.checkForUpdate()
    },
    beforeDestroy: function() {
      if (process.client) window.removeEventListener('resize', this.handleResize)
    },
  }
</script>

<style>
  #bodycontent::-webkit-scrollbar, #bodycontent ::-webkit-scrollbar {
      width: 0;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  #bodycontent::-webkit-scrollbar-thumb, #bodycontent ::-webkit-scrollbar-thumb {
      background: transparent;
  }
</style>

