<template>
<footer ref="footer" id="footer" class="fixed bottom-0 h-10 flex text-base bg-indigo-100 py-1 px-5 shadow items-center justify-between lg:px-10 shadow-inner border-white border-t-2 mb-0 w-full">
    <div class="links flex flex-row">
      <!-- <nuxt-link to="/about/immersive-reading" class="mr-4 xs:mr-7 hover:underline"><span class="hidden xs:inline">Our </span> <span class="hidden md:inline">Unique </span>Approach</nuxt-link>
      <nuxt-link to="/about" class="mr-4 xs:mr-7 hover:underline">About<span class="hidden sm:inline"> / Privacy</span></nuxt-link> -->
      <nuxt-link to="/contact" target="_blank" class="text-teal-800 mr-4 xs:mr-7 hover:underline">Contact </nuxt-link>
      <div class="text-gray-600"> {{formattedTime}} </div>
    </div>




    <nuxt-link to="/" class="hidden xs:block text-sm sm:text-base ml-50 md:ml-100 lg:ml-150 text-gray-600">
    &copy;&nbsp;2022<span class="text-gray-400 text-xs">,&nbsp;&nbsp;{{appVersion}}</span>
      <div v-if="!isProduction" class="inline text-gray-400 text-xs mr-0 pr-0">
        <span class="xs:hidden">xxs</span>
        <span class="hidden xs:inline sm:hidden">xs</span>
        <span class="hidden sm:inline md:hidden">sm</span>
        <span class="hidden md:inline lg:hidden">md</span>
        <span class="hidden lg:inline xl:hidden">lg</span>
        <span class="hidden xl:inline">xl</span>
      </div>
    </nuxt-link>
</footer>
</template>


<script>
export default {
  data () {
    return {
      isProduction: process.env.NODE_ENV === 'production',
      appVersion: process.env.appVersion,
      formattedTime: ''
    }
  },
  methods: {
    checktime() {
      let d = new Date()
      let tmstr = d.toLocaleTimeString().split(':')
      tmstr.pop()
      this.formattedTime = tmstr.join(':') // -> "7:38:05 AM"
    }
  },
  created() {
    setInterval(this.checktime, 100)
  },

}
</script>

