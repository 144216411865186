import firebase from 'firebase'
import { mapGetters, mapState } from 'vuex'
// import { sendPasswordResetEmail } from "firebase/auth";
// import { auth } from "../firebase"

export default {
  data () {
    return {
      adminCheck: 0,

     }
  },

  computed: {
    ...mapState('books', ['books', 'blocks']),
    ...mapGetters('books', [ 'getCurrentBook', 'getBlock', 'getLanguageList' ]),
    ...mapState([ 'user', 'account' ]),
    ...mapState('users', [ 'users', 'accounts']),
    // ...mapGetters(['isUserAdmin', 'isUserEditor' ]),

    userLibraryList() {
      // console.log('userLibraryList', this.account)
      if (!this.account) return
      let books = {}
      // books in user's courses
      // if (this.account.courses) for (let cid of this.account.courses) {
      //   console.log(cid)
      //   let course = this.coursesLookup[cid]
      //   if (course) for (let id of (course.syllabus||[])) if (this.bookDeckType(id)==='book') books[id] = true
      // }
      // books in user's library field
      for (let bid of  (this.account.library||[])) books[bid] = true
      return Object.keys(books).sort()
    },


  },

  methods: {
    isLoggedIn(){
      return (!!this.user && !!this.account)
    },
    isUserEmailVerified(user) {
      if (!user) user = this.user
      return user.email && user.emailVerified
    },
    isEditor(account) {
      if (!account) account = this.account
      if (this.isAdministrator(account)) return true
      return !!account && !!account.roles && !!account.roles.editor
    },
    isAdministrator(account) {
      if (!account) account = this.account
      return !!account && !!account.roles && !!account.roles.admin
    },
    isStudent(account) {
      if (!account) account = this.account
      if (this.isAdministrator(account)) return true
      return !!account && !!account.courses && !!account.courses.length
    },
    studentCourses(account) {
      if (!account) return []
      // if (this.isAdministrator(account)) return this
      return !!account && !!account.courses && !!account.courses.length
    },
    studentBooks(account) {
      if (!account) return []
      return !!account && !!account.courses && !!account.courses.length
    },



    userLogout() {
      return this.$store.dispatch('userLogout')
    },
    userLogin(email, password) {
      return this.$store.dispatch('userLogin', { email, password })
    },


    checkUserVerified() { // useful in template mount()
      if (['/account/logout'].indexOf(this.$route.path)>-1) return
      // console.log('checkUserVerified 1')
      if (this.user && !this.user.emailVerified) this.$router.push('/account/verify')
        else if (!this.user) setTimeout(() => {
          // console.log('checkUserVerified 2')
          if (this.user && !this.user.emailVerified) this.$router.push('/account/verify')
            else if (!this.user) setTimeout(() => {
              // console.log('checkUserVerified 3')
              if (this.user && !this.user.emailVerified) this.$router.push('/account/verify')
            }, 2000)
        }, 1000)
    },

    userDisplayName(account) {
      let email = `${account.email}`
      return account.fullName || account.displayName || account.email.split('@')[0]
    },
    userFB_URL(userid = '0') {
      let fbURL = 'https://console.firebase.google.com/u/0/project/immersive-language-lab/database/immersive-language-lab/data/accounts/'
      return `${fbURL}${userid}`
    },
    userPhoto(user) {
      if (!user) user = this.user
      return user.photoURL || ''
    },

    sendVerificationEmail(user=null) {
      if (!user) return this.$store.dispatch('sendVerificationEmail')
        // else {
        //   // firebase.auth().currentUser.sendEmailVerification()
        // }
    },
    sendPasswordResetEmail(userEmail=null) {
      return firebase.auth().sendPasswordResetEmail(userEmail)
      // return sendPasswordResetEmail(auth, userEmail).then((a) => {
      //   alert("Password reset email sent")
      // })
        // .then((link) => {
        //   // Construct password reset email template, embed the link and send
        //   // using custom SMTP server.
        //   return sendCustomPasswordResetEmail(userEmail, displayName, link);
        // }).catch((error) => {
        //   // Some error occurred.
        // });
    },

    // sendPasswordResetEmail ({state}) {
    //   if (!firebase.auth().currentUser) return
    //   const minimumEmailResendIntervalSeconds = 100
    //   let lastEmailtime = Date.parse(localStorage.getItem("lastVerificationEmail", 0))
    //   let elapsedSeconds = Math.round((new Date()-lastEmailtime) / 1000)
    //   if (!elapsedSeconds || elapsedSeconds > minimumEmailResendIntervalSeconds) {
    //     firebase.auth().currentUser.sendEmailVerification()
    //     // console.log('Requested new verification email.')
    //     localStorage.setItem("lastVerificationEmail", new Date().toString())
    //   }
    //   // else console.log(`${elapsedSeconds} seconds since last verification email.`)
    // },

    userCreate(email, password, displayName='') {
      return this.$store.dispatch('userCreate', { email, password, displayName })
    },



    isLoggedIn(){
      return (!!this.user && !!this.account)
    },
    isUserEmailVerified(user) {
      if (!user) user = this.user
      return user.email && user.emailVerified
    },
    isEditor(account) {
      if (!account) account = this.account
      if (this.isAdministrator(account)) return true
      // console.log(`isEditor(${account})`, !!account && !!account.roles && !!account.roles.editor)
      return !!account && !!account.roles && !!account.roles.editor
    },
    isAdministrator(account) {
      if (!account) account = this.account
      // console.log(`isAdministrator(${account})`, !!account && !!account.roles && !!account.roles.admin)
      return !!account && !!account.roles && !!account.roles.admin
    },

    enforceAdmin(check=0) {
      // check for user every second for 5 seconds. If user but not admin, redirect to base
      if (this.account || check>20) {
        if (!this.isLoggedIn()) this.$router.push('/account/login')
          else if (!this.isAdministrator()) this.$router.push('/')
      } else setTimeout(()=>{
        // console.log('No user account found', this.account)
        this.enforceAdmin(check+1)
      }, check*250)
    },


    userHasFlashcardsAccess(account, lang) {
      // console.log('userHasBookEditAccess', account, lang)
      // if (!account) account = this.account
      if (!account) return false
      if (!lang) return false
      if (this.isAdministrator(account)) return true
      if (!this.isEditor(account)) return false
      // console.log(Object.values(account.editBooks), Object.values(account.editBooks).indexOf(lang))
      if (!account.editFlashcards) return false
      return Object.values(account.editFlashcards).indexOf(lang)>-1
    },
    toggleEditorFlashcardsAccess(account, lang) {
      // if (!account) account = this.account
      if (account.admin) return
      let uid = account['.key'] // not uid ?
      if (!uid) uid = account.uid
      let editFlashcards = []
      if (account.editFlashcards) editFlashcards = Object.values(account.editFlashcards)
      let ind = editFlashcards.indexOf(lang)
      if (ind>-1) editFlashcards[ind] = null; else editFlashcards.push(lang)
      // save to DB
      this.$store.dispatch('users/accountUpdate', {uid, editFlashcards}).then(() => {
        this.$toasted.show(`<h4>Editor Flashcard Language access modified</h4>`)
      })
    },




    userHasBookEditAccess(account, bookid) {
      // console.log('userHasBookEditAccess', account, bookid)
      if (!account) account = this.account
      if (!account) return false
      if (!bookid) return false
      if (this.isAdministrator(account)) return true
      if (!this.isEditor(account)) return false
      // console.log(Object.values(account.editBooks), Object.values(account.editBooks).indexOf(bookid))
      if (!account.editBooks) return false
      return Object.values(account.editBooks).indexOf(bookid)>-1
    },


    userHasCourseAccess(account, courseid) {
      if (!account) account = this.account
      if (this.isAdministrator(account)) return true
      return account.courses && Object.values(account.courses).indexOf(courseid)>-1
    },

    userHasBookReadAccess(account, bookid) {
      if (!account) account = this.account
      if (this.isAdministrator(account)) return true
      let booklist = (this.userLibraryList || []).concat(this.freeBooks || [])
      console.log('checking access to book:', bookid, JSON.stringify(booklist, null, 2))
      if ((booklist || []).indexOf(bookid)>-1) return true
      let book = this.getCurrentBook
      // console.log('userHasBookReadAccess, currentBook', book.isFree, book.published)
      if (book && book.id===bookid && book.published && book.isFree) return true
    },

    toggleEditorBookAccess(account, bookid) {
      if (!account) account = this.account
      if (account.admin) return
      let uid = account['.key']
      let editBooks = []
      if (account.editBooks) editBooks = Object.values(account.editBooks)
      let ind = editBooks.indexOf(bookid)
      if (ind>-1) editBooks[ind] = null; else editBooks.push(bookid)
      // save to DB
      this.$store.dispatch('users/accountUpdate', {uid, editBooks}).then(() => {
        this.$toasted.show(`<h4>Editor book access modified</h4>`)
      })
    },
    toggleCourse(account, courseid) {
      if (account.admin) return
      // console.log(`for account ${account.fullName} toggling course ${courseid}`)
      let uid = account.uid || account['.key']
      if (!account.courses) account.courses = []
      let ind = Object.values(account.courses).indexOf(courseid)
      if (ind>-1) {
        this.$set(account.courses, ind, null)
      } else account.courses.push(courseid)
      // save to DB
      this.$store.dispatch('users/accountUpdate', {uid, courses: account.courses}).then(() => {
        this.$toasted.show(`<h4>User course access modified</h4>`)
      })
    },
    toggleEditor(account) {
      if (account.admin) return
      let uid = account['.key']
      if (!account.roles) account.roles= {editor: false}
      account.roles.editor = !account.roles.editor
      // save to DB
      this.$store.dispatch('users/accountUpdate', {uid, roles: account.roles}).then(() => {
        this.$toasted.show(`<h4>User role modified</h4>`)
      })
    },

    updateRegisteredAccounts() {
      let url = 'https://us-central1-immersive-language-lab.cloudfunctions.net/updateEmailVerifiedUsers'
      this.$axios.get(url).then(res => {
        console.log('updateRegisteredAccounts', res)
      })
    },


  },
}

