import { isArray } from 'lodash'
import { mapGetters, mapState } from 'vuex'
import booktools from '~/mixins/booktools'

export default {
  mixins: [booktools],

  data() {
    return {
      cardCache: {},

    }
   },

  computed: {
    // ...mapState('books', ['books', 'blocks']),
    // ...mapGetters('books', [ 'getCurrentBook', 'getBlock', 'getLanguageList' ]),
    ...mapState([ 'user', 'account' ]),
    // ...mapState('users', [ 'users', 'accounts']),
    // ...mapGetters(['isUserAdmin', 'isUserEditor' ]),

    ...mapState('progress', [ 'progress' ]),

    today() {
      return Math.round((new Date()).getTime() / (1000 * 3600 * 24))
    },

    mostRecentCourse() {
      if (this.progress && this.progress.courses_recent && this.progress.courses_recent[0]) return this.progress.courses_recent[0]
    },

    mostRecentBook() {
      if (this.progress && this.progress.books_recent && this.progress.books_recent[0]) return this.progress.books_recent[0]
    },

    userAllFlashcardsList() {
      if (!this.user || !this.account || !this.account.flashcards) return []
      let result = []
      Object.keys(this.account.flashcards).forEach(lang => {
        result = result.concat(Object.keys(this.account.flashcards[lang].scheduled))
      })
      // console.log('all user scheduled cards:', result)
      return result
    },

    userAllCardsCompleted() {
      // quick lookup list to see if a card is already completed
      if (!this.user || !this.account || !this.account.flashcards) return []
      let result = []
      Object.keys(this.account.flashcards).forEach(lang => {
        Object.keys(this.account.flashcards[lang].completed).forEach(id=>result[id]=true)
      })
      // console.log('all user scheduled cards:', result)
      return result
    },

    flashcardsDueList() {
      if (!this.user || !this.account || !this.account.flashcards) return []
      let result = []
      let langs = Object.keys(this.account.flashcards)
      let today = this.today
      // console.log('flashcardsDueList', langs)
      langs.forEach(lang => {
        let cardids = Object.keys(this.account.flashcards[lang].scheduled)
        // console.log('flashcardsDueList', lang, cardids)
        cardids.forEach(cardid => {
          let schedule = this.account.flashcards[lang].scheduled[cardid]
          // console.log('>> flashcardsDueList:', schedule.schedule, today)
          if (schedule.schedule <= this.today) result.push(schedule.id)
        })
      })
// console.log('all due cards:', result.length, result)
      return result
    },

    userCards() {
      if (!this.account || ! this.account.flashcards) return []
      let result = Object.keys(this.account.flashcards).map(lang => {
        let cardset = this.account.flashcards[lang]

        let scheduled = Object.values(cardset.scheduled || {}).filter(item => !!item)
        let completed = Object.keys(cardset.completed || {}).length
        let today = this.today //Math.round((new Date()).getTime() / (1000 * 360 * 24)) // number for today
        // BUCKETS: [1, 2, 3, 5, 7, 11]
        return {
          lang,
          due: scheduled.filter(item => item.schedule<=today).length,
          bucket1: scheduled.filter(item => item.bucket===1).length,
          bucket2: scheduled.filter(item => item.bucket===2).length,
          bucket3: scheduled.filter(item => item.bucket===3).length,
          bucket4: scheduled.filter(item => item.bucket===4).length,
          bucket5: scheduled.filter(item => item.bucket===5).length,
          bucket6: scheduled.filter(item => item.bucket===6).length,
          completed,
          scheduled: scheduled.length,
          total: scheduled.length + completed
        }
      })
      // console.log(JSON.stringify(result, null, 2))
      return result
    },

    cardsDueCount() {
      if (!this.blocksDbLoaded) return 0
        return (this.flashcardsDueList || []).length
    },

    percentDone() {
      if (!this.blocksDbLoaded) return 0
      if (this.account && this.account.progress && this.account.progress) {
        let bookProgress = this.account.progress.books[this.book.id]
        let prog = bookProgress.prog
        return prog.percent
      } else return 0
    },

    estRemaining() {
      if (!this.blocksDbLoaded) return ''
      if (this.account && this.account.progress && this.account.progress) {
        let bookProgress = this.account.progress.books[this.book.id]
        let summ = bookProgress.summary
        let prog = bookProgress.prog
        return this.formatSeconds((summ.time-prog.completed_time))
      } else return ''
    },

    progressObj() {
      if (!this.account) return false
      let prog = this.account.flashcards
      let lang = this.currentLang
      if (prog && prog[lang]) return prog[lang]
        else return {completed:{}, scheduled: {}}
    },

  },

  methods: {

    rightNow() {
      let rightNow = new Date().getTime()
      return rightNow
    },

    isBlockDone(blid) {
      if (!this.account || !this.account.progress) return false
      let block = this.getBlock(blid); if (!block) return false
      // non-content can be considered 'done'
      if (['par', 'title', 'header', 'flashcards'].indexOf(block.type)<0) return true
      // return progress value for this block, if found
      try {
        let blprog = this.getBlockProgressObject(blid)
        return blprog.isDone
        //return !!this.account.progress.books[block.book].blocks[blid].isDone
       }
       catch { return false }
    },

    // load user cards and delete any orphans
    checkUserCards() {
      if (!this.user || !this.account) return setTimeout(this.checkUserCards, 1000)
      if (!this.account.flashcards) return
      // console.log('Checking user cards...')
      Object.keys(this.account.flashcards).forEach(lang => {
        if (lang) Object.keys(this.account.flashcards[lang].scheduled).forEach(cardid => {
          // console.log('Checking to see if user card '+cardid+' still exists.')
          this.$fireModule.database().ref(`flashcards/${cardid}`).on("value", (snapshot) => {
            if (snapshot && !snapshot.exists()) {
              let prog = this.account.flashcards[lang]
              // console.log('deleting user card:', cardid, prog)
              if (this.cardCache[cardid]) delete this.cardCache[cardid]
              delete this.account.flashcards[lang].scheduled[cardid]
              this.updateUserCardProgress(prog, lang)
            }
          })
        })
      })
    },


    // resumeCourse(courseid, bookid) { }
    // resumeBook(bookid)
    // setBlockDone(bookid, blockid)
    // setFlashcardCompleted(fcid)
    // scheduleFlashcard(fcid,date)
    // logProgress_reading(block)
    // logProgress_cards(fcid)
    updateUserCardProgress(prog, lang){
      console.log('Update user progress', prog, lang)
      if (prog.scheduled && lang && this.account && this.user.uid) {
        if (!prog.completed) prog.completed = {}
        this.cardPractice[lang] = prog
        this.$fireModule.database().ref(`accounts/${this.user.uid}`).child(`flashcards/${lang}`).update(prog).then(() => {
          // this.$toasted.show(`<p>Updated block: “${blid}”</p>`)
          console.log('Modified user flashcard progress', this.user.uid, lang, prog)
        })
        .catch(error => console.error(error))
      } else console.error('updateUserCardProgress did not have enough info', prog, lang, this.user.uid)
    },

    preCacheUserCards() {
      if (!this.user || !this.account) return setTimeout(this.preCacheUserCards, 1000)
      this.loadCards(this.userAllFlashcardsList)
    },

    loadCards(cards=[]) {
      // console.log('loadCards', cards)
      let localReadCard = (cardid) => {
        return false
        const CACHE_TIMEOUT = 0 // 7 // one week in days
        let blobdata
        if (this.cardCache.hasOwnProperty(cardid)) return this.cardCache[cardid]
        else if (blobdata = JSON.parse(process.browser ? window.localStorage.getItem(`llab-${cardid}`) : '')) {
          let {savedDate, card} = blobdata
          if (!card || !savedDate || (savedDate <= this.today-CACHE_TIMEOUT)) return false
          this.$set(this.cardCache, card.id, card)
          return card
        }
      }
      let localWriteCard = (card) => {
        // return false
        if (card) {
          if (process.browser) window.localStorage.setItem(`llab-${card.id}`, JSON.stringify({savedDate:Date.now(), card }))
          this.$set(this.cardCache, card.id, card) // to cause reactive updates
          // console.log('local card write', card.id, card)
        }
      }
     let staleCards = cards.filter(cardid => !localReadCard(cardid))
      // let staleCards = cards.filter(()=>true)
      // console.log('Loading or refreshing', staleCards.length, 'cards', staleCards)
      // mark cards as loading so we won't try to fetch them twice
      staleCards.forEach(cardid => this.$set(this.cardCache, cardid, {id: cardid, type: 'loading' }) )
      // load cards from db and save
      // add some flag when loading is completed
      staleCards.forEach(cardid => {
        this.$fireModule.database().ref(`flashcards/${cardid}`).on("value", (snapshot) => {
          if (snapshot) {
            if (snapshot.exists()) localWriteCard(snapshot.val())
              else this.$set(this.cardCache, cardid, false)
          }
        })
      })
    },

    // getCard(cardid) {
    //   if (this.cardCache.hasOwnProperty(cardid)) return this.cardCache[cardid]
    //    else {
    //      this.loadCard(cardid)
    //      return {id: cardid, status:'loading'}
    //    }
    // },

    createProgressObject(account) {
      let progress = {
        courses: {},
        books: {},
        flashcards: {},
        logs: []
      }
      account.progress = progress
    },

    calculateBlockTimeSeconds(blid) { // this is lame
      const TXT_TIME = 70/33
      const WRD_TIME = 289/26
      const PHR_TIME = 586/111
      const FLASH_TIME = 5 * 60
      let block = this.getBlock(blid)
      let words = this.blockWords(blid)
      let trtype = block.trtype || 'none'
      if (block.type==="flashcards") return FLASH_TIME
      if (!this.isContentBlock(block.type)) return 0
      if (trtype === 'none') return Math.round(words.length * TXT_TIME + 2)
      if (trtype === 'words') return Math.round(words.length * WRD_TIME + 2)
      return Math.round(words.length * PHR_TIME + 2)
    },

    updateBookProgressTiming(force_recalculate=false, save=false){
      let bookProgress = this.account.progress.books[this.book.id]
      if (force_recalculate) bookProgress.blocks.forEach(bl => {
        bl.time = this.calculateBlockTimeSeconds(bl.blid)
      })
      let blocklist = Object.values(bookProgress.blocks)
      let total = Math.round(blocklist.reduce((sum, li) => sum + li.time, 0))
      let bocksDone = blocklist.filter(bl=> bl.isDone)
      let completed_time = Math.round(bocksDone.reduce((sum, li) => sum + li.time, 0))
      // bookProgress.summary = {bid, title: book.title, time, blocks: blocks.length}
      bookProgress.summary.time = total
      // bookProgress.prog = {isDone, completed_time, percent, current_block, updated, bocksDone}
      bookProgress.prog.completed_time = completed_time
      bookProgress.prog.percent = Math.round(completed_time/total*100)
      bookProgress.prog.updated = this.rightNow()
      bookProgress.prog.blocksDone = bocksDone.length
      //  console.log(`updateBookProgressTiming`, total, bocksDone.length)
      if (save) this.saveDB_bookProgress(this.book.id, bookProgress.prog)
    },

    setProgressBlockDone(blid, level=1) {
      // account.progress.books[this.book.id] = {summary, prog, blocks}
      if (this.isBlockDone(blid, level)) return
      let block = this.getBlock(blid)
      let bookProgress = this.account.progress.books[block.book]
      let blockProgress = bookProgress.blocks[block.id] // {blid, time, updated, isDone}
      if (!blockProgress)  blockProgress = this.newBlockProgObj(blid)

// console.log('setProgressBlockDone', blockProgress)

      // fix legacy objects
      if (this.isContentBlock(block.type)) {
        if (!blockProgress.done) blockProgress.done=[false,false,false]
        for (let i = 0; i < level; i++) blockProgress.done[i] = true
        blockProgress.isDone = (blockProgress.done[0] && blockProgress.done[1] && blockProgress.done[2])
      }

      blockProgress.updated = this.rightNow()

      // store and save
      bookProgress.blocks[blid] = blockProgress
      this.saveDB_blockProgress(blid, blockProgress)
      // update time remaining
      this.updateBookProgressTiming(false, true) // save
    },

    newBlockProgObj(blid) {
      let block = this.getBlock(blid)
      if (block.trtype!='phrases') return {blid, time:10, isDone:false, updated:0}
       else return {blid, time:10, done:[false,false,false], isDone:false, updated:0}
    },

    getBlockProgressObject(blid) {
      let block = this.getBlock(blid)
      try {
        let pr = this.account.progress.books[block.book].blocks[blid]
        if (pr) {
          if (block.trtype!=='phrases') return pr
           else if (!isArray(pr.done)) pr.done = [!!pr.isDone, false, false] // fix legacy
          pr.isDone = pr.done[0] && pr.done[1] && pr.done[2]
          return pr
        }
        return this.newBlockProgObj(blid)
      } catch {
        return this.newBlockProgObj(blid)
      }
    },

    saveDB_initialProgressObject(bookProgressObject) {
      const DB_ACCESS_KEY = `accounts/${this.user.uid}`
      const BOOK_PROGRESSOBJ_KEY = `progress/books/${bookProgressObject.bookid}`
      // bookProgressObj: {bookid, summary, blocks, prog}
      let dbref = this.$fireModule.database().ref(DB_ACCESS_KEY)
      dbref.once('value', (snapshot) => {
        if (!snapshot.hasChild(BOOK_PROGRESSOBJ_KEY)) {
          // console.log('No book progress object found, creating initial object')
          dbref.child(BOOK_PROGRESSOBJ_KEY).update(bookProgressObject).then(() => {
            // console.log('... Created initial book progress object', bookProgressObject)
          })
          .catch(error => console.error(error))
        } // else console.log('Book progress object detected')
      })

    },

    saveDB_blockProgress(blid, blockProgress) {
      blockProgress = JSON.parse(JSON.stringify(blockProgress))
      let block = this.getBlock(blid)
      const DB_ACCESS_KEY = `accounts/${this.user.uid}`
      const BLOCK_PROGRESS_KEY = `progress/books/${block.book}/blocks/${block.id}`
      // blockProgress: {blid, time, updated, isDone}
// console.log('Saving block progress: ', blockProgress)
      let dbref = this.$fireModule.database().ref(DB_ACCESS_KEY)
      dbref.child(BLOCK_PROGRESS_KEY).update(blockProgress).then(() => {
        console.log('Modified user blockProgress', this.user.uid, blockProgress)
      })
      .catch(error => console.error(error))
    },

    saveDB_bookProgress(bookid, bookProgress) {
      // let block = this.getBlock(blid)
      bookProgress = JSON.parse(JSON.stringify(bookProgress))
      const DB_ACCESS_KEY = `accounts/${this.user.uid}`
      const BOOK_PROGRESS_KEY = `progress/books/${bookid}/prog`
      // bookProgress: {isDone, completed_time, percent, current_block, updated}
// console.log('Saving book progress: ', bookProgress)
      let dbref = this.$fireModule.database().ref(DB_ACCESS_KEY)
      dbref.child(BOOK_PROGRESS_KEY).update(bookProgress).then(() => {
        console.log('Modified blockProgress', this.user.uid, bookProgress)
      })
      .catch(error => console.error(error))
    },




    initBookProgressObject(book, account) {
      if (!account) return
      // make sure all blocks are loaded and account is loaded
      let bid = book.id
      // create book summary object
      if (account && !account.progress) this.createProgressObject(account)
      if (account.progress.books[this.book.id]) return
      // calculate block summary entry for each block
      // blocks
      let blocks = {}, updated = this.rightNow()
      book.blocks.forEach(blid => {
        let time = this.calculateBlockTimeSeconds(blid)
        if (time) blocks[blid] = {blid, time, updated, isDone: false}
      })
      // book summary
      let blocklist = Object.values(blocks)
      let time = blocklist.reduce((sum, li) => sum + li.time, 0)
      let summary = {bid, title: book.title, time, blocks: blocklist.length}
      let bookid = this.book.id
      // progress summary
      let prog = {isDone: false, completed_time: 0, percent: 0, current_block: this.book.blocks[0], updated}
      // assign
      let bookProgressObj = {bookid, summary, prog, blocks}
      account.progress.books[bookid] = bookProgressObj
      this.saveDB_initialProgressObject(bookProgressObj)
    },

    checkProgressSave() {
      // every minute check to see if it is time to save. Save every 5 minutes
      const saveInterval = 1 * 60 * 1000
      let rightNow = this.rightNow()
      // save position if
      if (this.lastSave) {
        if (rightNow-this.lastSave > saveInterval) this.progressSave()
      } else this.lastSave = rightNow
      let nextSaveTime = ((this.lastSave + saveInterval) - rightNow) + 100
      // console.log(`checkProgressSave() nextSave in: ${nextSaveTime}ms`)
      setTimeout(this.checkProgressSave, nextSaveTime) // 1 minute to next check
    },
    progressSave() {
      // this.savePosition()
      // save other progress stuff
      this.lastSave = this.rightNow()
    },

    getFirstBlockNotDone() {
      let index = 0, blid = ''
      if (this.isBlockDone(this.book.blocks[index])) {
        while (this.book.blocks[index] && this.isBlockDone(this.book.blocks[index])) index++
      }
      if (this.book.blocks[index]) blid = this.book.blocks[index]
       else blid = this.book.blocks[0]
      return blid
    },

  },





  mounted() {
    this.preCacheUserCards()
  },


}

