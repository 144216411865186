<template>
<div class="relative p-0 m-0 block group">
  <template v-if="mode!='account'">
    <span class="sm:inline-block text-sm md:text-base ml-5 md:ml-8 cursor-pointer">{{title}}&nbsp;▾</span>

    <!-- dropdown portion -->
    <div v-if="menuitems && menuitems.length>0"
        class="invisible absolute top-20 bg-white border shadow rounded-lg group-hover:visible cursor-pointer border-gray-300 border-2"
        :class="[wid?wid:'w-56', pos?pos:'-ml-10']">

      <div v-for="(l,i) in menuitems" :key="'menu6_'+i">
        <template v-if="!l.type || (l.type==='admin'&&isAdministrator()) || (l.type==='editor'&&isEditor())">

          <!-- book display format  -->
          <template v-if="l.ltype==='book'">
            <nuxt-link :to="l.href">
              <div class="relative mb-1 h-14 w-full flex flex-wrap cursor-pointer hover:bg-gray-200" style="min-height: 52px"
                  :class="[l.style==='upcoming'?'opacity-50':'']" >
                <img class="absolute left-0 top=0 h-12 ml-1 mt-1" :src="l.img || '/book.svg'" />
                <div class="title ml-10 mr-1 mt-1 pl-2 text-gray-800 text-md">{{l.a}}</div>
              </div>
            </nuxt-link>
          </template>


          <!-- subtitle  -->
          <div v-else-if="l.ltype==='subtitle'" class="text-center text-italic text-xs text-gray-400">{{l.a}}</div>

          <!-- divider have no l.a  -->
          <hr v-else-if="l.ltype==='hr'" class="w-full"  />

          <!-- link display format  -->
          <template v-else-if="l.a && l.a.length>0 && l.href">
            <!-- internal links -->
            <nuxt-link v-if="l.href.indexOf('http')==-1" :to="l.href" class="block hover:bg-gray-200 p-2 "
              :class="[l.style && l.style=='additional'? 'text-green-800 font-bold' : '',
                      l.style && l.style=='upcoming'? 'opacity-50 text-italic' : '',
              ]" >
              <img v-if="l.img" :src="l.img || '/book.svg'" class="h-6 inline rounded shadow" /> {{l.a}}
            </nuxt-link>
            <!-- external links  -->
            <a v-else :href="l.href" target="_blank" class="block hover:bg-gray-200 p-2 ">
              <img v-if="l.img" :src="l.img || '/book.svg'" class="h-6 inline rounded shadow" /> {{l.a}}</a>
          </template>


        </template>
      </div>
    </div>
  </template>
  <template v-else>

    <template v-if="user && account">
      <span class="sm:inline-block text-sm ml-5 md:ml-8 pr-5 relative "> {{user.displayName ? user.displayName : user.email.split('@')[0]}}&nbsp;<img :src="user.photoURL || '/profile.svg'" class="w-8 h-8 absolute top-0 right-0 fill-current p-0 -mr-6 -mt-1 rounded-full shadow "/>
      </span>
      <!-- dropdown portion -->
      <div class="invisible absolute top-20 bg-white shadow rounded-lg group-hover:visible cursor-pointer drop border-gray-300 border-2"
        :class="[wid?wid:'w-40', pos?pos:'-ml-10']">
        <div v-for="(l,i) in menuitems" :key="'menu5_'+i">
          <template v-if="l.a && l.a.length && l.href">
            <nuxt-link v-if="l.href.indexOf('http')==-1" :to="l.href" class="block hover:bg-gray-200 p-2 " :class='l.class'>
              <img v-if="l.img" :src="l.img || '/book.svg'" class="h-6 inline rounded shadow" /> {{l.a}}</nuxt-link>
            <a v-else :href="l.href" target="_blank" class="block hover:bg-gray-200 p-2 ">{{l.a}}</a>
          </template>
          <hr v-else class="w-full"  />
        </div>
      </div>
    </template>
    <!-- logged-out state  -->
    <nuxt-link v-else class="sm:inline-block text-base ml-5 md:ml-8 pr-4 relative hover:text-teal-600 font-bold" to="/account/login">Login&nbsp;<svg class="w-12 h-12 absolute top-0 right-0  fill-current p-0 -mr-8 -mt-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><circle cx="12" cy="12" r="10" class="primary"></circle><path class="secondary" d="M3.66 17.52A5 5 0 0 1 8 15h8a5 5 0 0 1 4.34 2.52 10 10 0 0 1-16.68 0zM12 13a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"></path></svg>
    </nuxt-link>

  </template>
</div>
</template>



<script>
import usertools from '~/mixins/usertools'
// import VLazyImage from "v-lazy-image"

export default {
  mixins: [usertools],
  props: ['title', 'menuitems', 'mode', 'wid', 'pos'],
  components: {},
}
</script>