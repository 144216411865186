// This simple Nuxt plugin grabs the incoming affid value from query param and sticks it into localstorage
// From there it will be added to new user's account if and when an account is created

// If no other params are found, the app is redirected to the base pathname to hide affid

// TODO: extend this to support other marketing tracking codes such as Google ads



export default function () {
  if (!process.browser) return
  var affid = new URLSearchParams(window.location.search).get('affid')
  if (affid) {
    window.localStorage.setItem("affid", affid)
    if (window.location.search.split('&').length===1) window.location = window.location.pathname
  }
}
