import Vue from 'vue'
import { firebaseAction } from 'vuexfire'

Vue.config.devtools = true

//****************************************/
export const state = () => ({
  users: [],
  accounts: []
})


//****************************************/
export const getters = {
  // getCurrentBook(state) {
  //   let book = state.books.filter(bk => bk.id===state.currentBook.id)
  //   if (book) return book[0]
  // },
  // getRandomBook(state) {
  //   return state.books[Math.floor(Math.random()*state.books.length)]
  // },
  // getBook: (state) => (bookid) => {
  //   let index = state.books.map(bk=> bk.id).indexOf(bookid)
  //   if (index>-1) return state.books[index]
  // },
  // getBlock: (state) => (blockid) => {
  //   let index = state.blocks.map(bl=> bl.id).indexOf(blockid)
  //   if (index>-1) return state.blocks[index]
  //    else return {isLoading: true, content: []}
  // },
  // getLanguageList(state) { // list of all lang2 language codes
  //   return state.books.map(bk => bk.lang2).filter((v,i,s) => s.indexOf(v)===i)
  // },
  // getSelectionObj(state) {
  //   return tokenObj(state.selectionToken)
  // },
}

//****************************************/
export const mutations = {
  setUserData(state, userid, userdata) {
    this.$fireModule.database().ref(`accounts/${userid}`).update(userdata)
  }
  // setCurrentBook(state, bookid) {
  //   state.currentBook.id = bookid
  // },
  // setSelectionToken(state, token) {
  //   state.selectionToken = token
  // },
  // setUserReadingLevel(state, level) {
  //   state.userReadingLevel = level
  // }
}

export const actions = {
  loadUsers: firebaseAction(function ({state, getters, commit, bindFirebaseRef}) {
    // console.log('loadUsers action in "users" vuex store')
    // commit('setCurrentBook', bookid)
    return bindFirebaseRef('accounts', this.$fireModule.database().ref(`accounts/`))
  }),

  accountUpdate ({ state }, update) {
    // console.log('Action accountUpdate: ', update)
    return this.$fireModule.database().ref(`accounts/${update.uid}`).update(update).then(() => {
      //
    }).catch(error => console.error(error))
  },

  // bindUsersRef: firebaseAction(context => {
  //   console.log('bindUsersRef')
  //   context.bindFirebaseRef('users', this.$fireModule.database().ref('users'))
  // }),
}












